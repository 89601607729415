.cell {
  flex: 1 0 33.333%;
  margin: 0;
  border: 3px solid white;
  color: white;
  background: rgb(220, 219, 234);
  font-size: 2rem;
  cursor: pointer;
}

.cell:hover {
  background: rgb(198, 197, 211);
}

.cell-valid {
  background: rgb(218, 231, 206);
}

.cell-void {
  visibility: hidden;
}
