.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: url(./background.png);
}

.app h1 {
  font-size: 3rem;
  color: rgb(206, 221, 229);
  background: white;
  padding: 15px;
  border: 5px solid rgb(206, 221, 229);
}
