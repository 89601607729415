.controls {
  margin-top: 25px;
}

.controls button {
  background: white;
  padding: 1rem;
  margin: 5px;
  border: 5px solid rgb(206, 221, 229);
}
